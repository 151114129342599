<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card
      class="py-6 px-6 mt-3 create-project-height"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col flex>
          <div
            class="back-to-projects"
            @click="handleBackClick"
          >
            <v-icon color="blue">
              mdi-chevron-left
            </v-icon>
            <p
              v-if="showAddUsersView"
              class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold"
            >
              {{ $t('projects.create_project.back_to_project_info') }}
            </p>
            <p
              v-else
              class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold"
            >
              {{ $t('projects.create_project.back_to_projects') }}
            </p>
          </div>
        </v-col>
        <v-col
          v-if="showAddUsersView"
          cols="auto"
          class="pointer"
          @click="closeShowAddUsersView"
        >
          <v-icon>mdi-close</v-icon>
        </v-col>
      </v-row>
      <template v-if="showAddUsersView">
        <p class="text-start display-1 font-weight-bold my-2">
          {{ $t('projectUsers') }}
        </p>
        <div class="mt-6 d-flex">
          <v-chip
            :class="{ 'blue--text': tableFilter === 'all' }"
            width="200px"
            :color="tableFilter === 'all' ? 'blue-light' : 'gray-light'"
            label
            @click="changeFilter('all')"
          >
            <div class="font-weight-bold px-2">
              {{ $t('all') }} <span class="ml-2">{{ users.length - selectedUserLength }}</span>
            </div>
          </v-chip>
          <div class="ml-2">
            <v-chip
              :class="{ 'blue--text': tableFilter === 'selected' }"
              width="200px"
              :color="tableFilter === 'selected' ? 'blue-light' : 'gray-light'"
              label
              @click="changeFilter('selected')"
            >
              <div class="font-weight-bold px-2">
                {{ $t('selected') }} <span class="ml-2">{{ selectedUserLength }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <v-row class="d-flex align-center justify-space-between mt-5">
          <v-col
            :cols="$vuetify.breakpoint.smAndUp ? 'auto' : 12"
            class="d-flex align-center"
          >
            <v-text-field
              v-model="search"
              type="text"
              color="blue"
              prepend-inner-icon="mdi-magnify"
              placeholder="Search by name, email, etc."
              class="rounded-lg field-theme"
              background-color="#F9F9FB"
              dense
              height="38px"
              hide-details
              clear-icon="body-2"
            />
          </v-col>
          <v-col
            flex
            class="d-flex justify-space-between align-center"
          >
            <ProjectUserFilter @filters="applyFilters" />
            <v-sheet
              color="gray-100"
              width="50px"
              class="d-flex ml-4 align-center justify-center"
              height="50px"
              rounded="lg"
              v-on="on"
            >
              <v-icon color="gray-ish">
                mdi-cog-outline
              </v-icon>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row
          v-if="isAnyFilterApplied"
          class="mt-3"
        >
          <v-chip
            v-for="role in appliedFilters.roles"
            :key="role.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('role') }}: {{ role.name }}
          </v-chip>
          <v-chip
            v-for="project in appliedFilters.projects"
            :key="project.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ project.name }}
          </v-chip>
          <v-chip
            v-for="tag in appliedFilters.tags"
            :key="tag.id"
            color="gray-light"
            class="ma-2 gray-ish--text font-weight-semi-bold"
            label
          >
            {{ $t('project') }}: {{ tag.title }}
          </v-chip>
          <v-btn
            class="text-capitalize mt-1"
            color="blue"
            text
            @click="clearAll"
          >
            {{ $t('clearAll') }}
          </v-btn>
        </v-row>
        <ProjectUserTable
          :users-data="users"
          :table-filter="tableFilter"
          @updateUser="updateUser"
        />
      </template>
      <template v-else>
        <v-row justify="center">
          <div
            class="create-project-layout"
          >
            <h2 class="text-start">
              {{ $t('projects.create_project.title') }}
            </h2>
          </div>
        </v-row>
        <v-form
          ref="form"
          role="updateProfileForm"
          @submit.prevent="handleSubmit(updateProfile)"
        >
          <v-row
            justify="center"
            class="my-6"
          >
            <div
              class="create-project-layout"
            >
              <v-hover v-slot="{ hover }">
                <div
                  class="project-logo"
                  :style="imageStyle"
                  :class="{ hovering: hover }"
                  @click="openFileDialog"
                  @drop.prevent="handleDrop"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <input
                    ref="fileInput"
                    type="file"
                    :accept="`${projectImageTypes.join(', ')}`"
                    style="display: none"
                    @change="handleFileChange"
                  >
                  <template v-if="!imageSrc">
                    <p class="ma-0 fs-14px">
                      {{ $t('drag_and_drop.part1') }}
                    </p>
                    <p class="ma-0 fs-14px">
                      {{ $t('drag_and_drop.part2') }}
                    </p>
                    <p class="ma-0 fw-semibold mt-2 blue--text fs-14px">
                      {{ $t('drag_and_drop.part3') }}
                    </p>
                  </template>
                  <template v-if="hover && imageSrc">
                    <v-icon
                      class="edit-icon"
                      @click.stop="openFileDialog"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      class="delete-icon"
                      @click.stop="removeImage"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </div>
              </v-hover>
            </div>
          </v-row>
          <v-row justify="center">
            <div
              class="create-project-layout"
            >
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('projects.create_project.project_name') }} <strong class="red--text text--lighten-1">*</strong>
                <span class="ml-auto gray-ish--text">{{ form.projectName.length }}/100</span>
              </p>
              <v-text-field
                v-model="form.projectName"
                type="text"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('projectName')"
                :rules="[rules.required, rules.maxProjectNameLength]"
                clear-icon="body-2"
              />
            </div>
          </v-row>

          <v-row justify="center">
            <div
              class="create-project-layout"
            >
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('projects.create_project.project_key') }} <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field
                ref="projectKey"
                v-model="form.projectKey"
                type="text"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                height="38px"
                :placeholder="$t('projects.create_project.project_key') "
                :rules="[rules.required, rules.min2Chars, rules.projectKeyMax10Chars, rules.projectKeyFormat]"
                clear-icon="body-2"
                @input="isHandleAvailable"
              />
            </div>
          </v-row>

          <v-row justify="center">
            <div
              class="create-project-layout"
            >
              <p class="d-flex fs-14px text-theme-label mb-1 font-weight-medium">
                {{ $t('projects.create_project.description') }}
                <span class="ml-auto gray-ish--text">{{ form.description.length }}/500</span>
              </p>
              <v-textarea
                v-model="form.description"
                class="rounded-lg field-theme pt-0"
                background-color="#F9F9FB"
                :placeholder="$t('projects.create_project.description')"
                :rules="[rules.maxDescriptionLength]"
                rows="3"
                clear-icon="body-2"
              />
            </div>
          </v-row>
        </v-form>
      </template>
      <v-row
        justify="end"
        class="mt-4"
      >
        <v-col cols="12">
          <v-flex class="d-sm-flex justify-end position-absolute right-0 bottom-0 pa-4">
            <v-btn
              v-if="showAddUsersView && toBeSelectedUserLength && tableFilter === 'selected'"
              height="40px"
              color="primary"
              depressed
              class="text-capitalize rounded-lg btn-theme black--text mr-4 mt-2"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
              @click="removeUsers"
            >
              {{ $t('projects.create_project.remove') }} {{ toBeSelectedUserLength }}
            </v-btn>
            <div
              v-if="!showAddUsersView || (showAddUsersView && !toBeSelectedUserLength)"
              class="d-flex justify-center align-end"
            >
              <v-btn
                height="40px"
                color="primary"
                depressed
                :loading="isCreateDisabled"
                class="text-capitalize btn-theme rounded-lg mt-2"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150xp'"
                @click="createProject"
              >
                {{ $t('createProject') }}
              </v-btn>
            </div>
          </v-flex>
        </v-col>
      </v-row>
    </v-card>
    <ProjectDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('projects.create_project.close_dialog.title')"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-container>
</template>

<script>
import ProjectDiscardDialog from '@/components/Project/ProjectDiscardDialog.vue';
import ProjectUserTable from '@/components/Project/ProjectUserTable.vue';
import ProjectUserFilter from '@/components/Project/ProjectUserFilter.vue';
import { users } from '@/constants/data.js';
import { mapActions } from 'vuex';
import makeProjectsService from '@/services/api/project'
import { showErrorToast } from '@/utils/toast';
import { debounce } from 'debounce';
import fileValidator from '@/mixins/fileValidator.js'
import { projectImageTypes } from '@/constants/fileTypes.js'

export default {
  name: 'ProjectCreate',
  components: {
    ProjectDiscardDialog,
    ProjectUserTable,
    ProjectUserFilter,
  },
  mixins: [ fileValidator ],
  data() {
    return {
      projectImageTypes,
      isCreateDisabled: false,
      imageSrc: '',
      file: null,
      users: users,
      form: {
        projectName: '',
        projectKey: '',
        description: '',
        users: [],
      },
      showConfirmBackDialog: false,
      showAddUsersView: false,
      toBeSelectedUser: [],
      selectedUser: [],
      tableFilter: 'all',
      search: '',
      appliedFilters: {
        roles: [],
        projects: [],
        tags: [],
      },
      rules: {
        required: (value) => !!value || this.$t('required'),
        min2Chars: (v) => (v && v.length >= 2) || this.$t("min2Chars"),
        projectKeyMax10Chars: (v) => (v && v.length <= 10) || this.$t('projects.create_project.projectKeyMax10Chars'),
        projectKeyFormat: (v) => /^[A-Za-z0-9_-]+$/.test(v) || this.$t('projects.create_project.projectKeyFormat'),
        maxProjectNameLength: (v) => (v && v.length <= 100) || this.$t('projects.create_project.maxProjectNameLength'),
        maxDescriptionLength: (v) => (!v || v.length <= 500) || this.$t('projects.create_project.maxDescriptionLength'),
      }
    };
  },
  computed: {
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    toBeSelectedUserLength() {
      return this.users.filter((user) => user.toBeSelected).length;
    },
    selectedUserLength() {
      return this.users.filter((user) => user.selected).length;
    },
    isAnyFilterApplied() {
      return this.appliedFilters.roles.length || this.appliedFilters.projects.length || this.appliedFilters.tags.length;
    },
  },
  mounted() {
    this.users.forEach((user) => {
      this.$set(user, 'toBeSelected', false);
    });
  },
  methods: {
    ...mapActions({
      uploadToServer: 'attachment/uploadToServer',
      add: 'project/add'
    }
    ),
   async validateProjectKey(key){
      const handle = this.$route.params.handle;
      const projectService = makeProjectsService(this.$api);
      
      try {
        const projectExists = await projectService.validateProjectKey(handle, key);
        return projectExists?.status == 200 ? true : false;
      } catch (error) {
        return false; 
      }
    },
    isHandleAvailable: debounce(async function(event){
      this.updateProjectKey(event);
    }, 1000),
    async updateProjectKey(key){
      if(
        !this.$refs.projectKey.errorBucket.length 
        && !(await this.validateProjectKey(key))
      ){
        this.$refs.projectKey.errorBucket = [this.$t('projects.create_project.projectKeyExists', { projectKey: key })]
      }
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    async createProject() {

      if(this.validateForm()){

        if (this.$refs.projectKey.errorBucket.length) {
          return;
        }

      this.isCreateDisabled = true;
      const payload = {
        name: this.form.projectName,
        key: this.form.projectKey,
        customFields: {
          description: this.form.description,
          status: "active",
          star: false
        },
        source: 'testfiesta-web',
        externalId: 'testfiesta',
      };
      await this.add({ swal: this.$swal, handle: this.$route.params.handle, payload })
        .then(async response => {
          const project = response.data;
          
          this.$router.push({name: "ProjectsView"});

          if(project.uid && this.file){
            const handle = this.$store.getters['user/currentAccount'].handle
            const mediaType = 'attachment'
            const relatedToUid = project.uid;
            const relatedTo = 'project';
            await this.uploadToServer({handle, relatedTo, relatedToUid, mediaType, file: this.file})
          }
            
        })
        .catch(error => {
          showErrorToast(this.$swal, error?.response?.data?.message);
          console.error("Failed to create project:", error);
          this.isCreateDisabled = false;
        });

      }
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      const validationResult = this.validateMimeTypes(files, projectImageTypes);

      if (!validationResult.valid) {
        showErrorToast(this.$swal, this.$t('error.fileFormatNotSupported'));
      } else {
        const file = event.target.files[0];

        this.previewImage(file);
      }
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
        this.file = file;
      }
    },
    removeImage() {
      this.imageSrc = '';
    },
    handleBackClick() {
      if (this.showAddUsersView) {
        this.showAddUsersView = false;
      } else {
        this.showConfirmBackDialog = true;
      }
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'ProjectsView' });
    },
    closeShowAddUsersView() {
      this.showAddUsersView = false;
      this.toBeSelectedUser = [];
    },
    changeFilter(filter) {
      this.tableFilter = filter;
    },
    addUsers() {
      this.users.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', true);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    removeUsers() {
      this.users.forEach((user) => {
        if (user.toBeSelected) {
          this.$set(user, 'selected', false);
          this.$set(user, 'toBeSelected', false);
        }
      });
    },
    updateUser(user) {
      const index = this.users.findIndex((item) => item.id === user.id);
      if (index !== -1) {
        this.$set(user, 'selected', this.tableFilter === 'all');
        this.$set(user, 'toBeSelected', false);
      }
    },
    clearAll() {
      this.appliedFilters = {
        roles: [],
        projects: [],
        tags: [],
      };
    },
    applyFilters(filters) {
      this.appliedFilters = filters;
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}
.search_input {
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 300px;
  }
}
.project-logo {
  border-radius: 50%;
  border: 2px dashed #D0D5DD;
  width: 136px;
  height: 136px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
  background-color: #F9FAFB;
}

.project-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}

.edit-icon,
.delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.delete-icon {
  margin-left: 60px;
}

.project-logo:hover .edit-icon,
.project-logo:hover .delete-icon {
  display: block;
}
.create-project-layout {
  width: 100%;
  max-width: 438px;
}
.create-project-height {
  min-height: calc(100vh - 24px - 6.25rem);
  height: 100%;
}
</style>
